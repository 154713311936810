<template>
  <div>

    <Form
      ref="form"
      route="payments"
      :form="form"
      :error="error"
      :isEdit="true"
      :payment_categories_id="payment_categories_id"
      :medicines_id="medicines_id"
      :lab_procedures_id="lab_procedures_id"
      :doctors="doctors"
      :labProcedures="labProcedures"
      :display="display"
      :payment_slips="[]"
      @btnCheckoutOnClick="btnCheckoutOnClick"
    />

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="modalPatientOnSelect"
      @submitedPatient="modalPatientOnSelect" />

    <ModalMedicine
      :selectedItem="form.medicines_id"
      :isHospitalTransaction="true"
      :checkbox="true"
      :isAbjad="true"
      @chosenMedicine="modalMedicineOnSelect" />

    <ModalService
      :selectedItem="form.payment_categories_id"
      @chosenItem="modalServiceOnSelect"
      @customSubmitedCategory="modalServiceOnSelect" />

    <ModalLabProcedure
      :selectedItem="form.lab_procedures_id"
      @chooseItem="modalLabProcedureOnSelect" />

    <ModalCheckout
      :isHospitalTransaction="true"
      :form="form"
      :error="error"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @modalCheckoutOnShow="modalCheckoutOnShow"
      @confirmCheckout="confirmCheckout" />

  </div>
</template>

<script>

// components
import Form from '@/component/payments/NewForm.vue'
import ModalPatient from '@/component/general/ModalPatient.vue'
import ModalMedicine from '@/component/general/ModalMedicine.vue'
import ModalService from '@/component/general/ModalPaymentCategory.vue'
import ModalLabProcedure from '@/component/lab-procedures/ModalSelectLabProcedure.vue'
import ModalCheckout from '@/component/general/ModalCheckout.vue'

// modules
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { getUser } from '@/core/services/jwt.service.js'
import module from '@/core/modules/CrudModule.js'
import PaymentModule from '@/core/modules/PaymentModule.js'
import validation from '@/core/modules/ValidationModule.js'
import ApiService from '@/core/services/api.service.js'
import JwtService from "@/core/services/jwt.service"

export default {

  components: {
    Form,
    ModalPatient,
    ModalMedicine,
    ModalCheckout,
    ModalService,
    ModalLabProcedure
  },

  data() {
    return {
      // display data
      display: {
        patient_name: '',
        medicines_name_array: [],
        payment_categories_name_array: [],
        lab_procedures_name_array: [],
      },
      // form
      form: {
        patient_id: this.$route.query.patient_id ?? '',
        doctor_id: this.$route.query.doctor_id ?? '',
        appointment_id: this.$route.query.appointment_id ?? '',
        coupon_id: null,
        coupon_name: '',
        coupon_is_percent: 0,
        coupon_percentage_amount: 0,
        coupon_amount: 0,
        payment_status_id: '',
        bank_id: '',
        bank_account_id: '',
        image: '',
        card_number: '',
        card_type: '',
        edc_struct: '',
        bank_account_number: '',
        bank_account_name: '',
        e_wallet_id: '',
        payment_method_id: 1,
        payment_categories_id: [],
        medicines_id: [],
        lab_procedures_id: [],
        surcharge: 0,
        total_amount: 0,
        discount: 0,
        total_promo_amount: 0,
        final_amount: 0,
        tax: 0,
        payment_amount: 0,
        change_amount: 0,
        unpaid_amount: 0,
        count_unpaid_amount: 0,
        notes: '',
        invoice_notes: '',
      },
      // form error
      error: {
        patient_id: '',
        doctor_id: '',
        payment_categories_id: '',
        medicines_id: '',
        lab_procedures_id: '',
        surcharge: '',
        total_amount: '',
        discount: '',
        final_amount: '',
        tax: '',
        bank_id: '',
        bank_account_id: '',
        image: '',
        card_number: '',
        card_type: '',
        edc_struct: '',
        bank_account_number: '',
        bank_account_name: '',
      },
      // other
      payment_categories_id: [],
      medicines_id: [],
      lab_procedures_id: [],
      // master data
      doctors: [],
      labProcedures: [],  
      // doctors: [{ label: 'Pilih Dokter', id: '', isDisabled: true }],
      // notification
      new_invoice: null,
      // current user
      currentUser: getUser()
    }
  },

  methods: {

    async getDoctors() {
      this.doctors = await PaymentModule.getDoctors(this.form.patient_id)
    },

    async getData() {
      let payment = await module.get(`payments/${this.$route.params.id}`)
      for (const [key, value] of Object.entries(payment)) {
        this.form[`${key}`] = value
      }
      this.display.patient_name = this.form.patient_name
      this.payment_slips = await module.get(`payment-slips/get-by-payment/${this.$route.params.id}`)
      if (this.form == null || this.form.payment_status_id == 3) {
        this.$router.push('/payment/list')
      } else {
        this.form['_method'] = 'put'
        this.form.payment_method_id = 1
        this.form.surcharge = parseInt(this.form.surcharge).toLocaleString('id-ID')
        this.form.discount = parseInt(this.form.discount).toLocaleString('id-ID')
        const queryParams = `?with_promo=true`
        let response = await module.get(`payment-details/${this.$route.params.id}${queryParams}`)
        response.forEach(value => {
          let new_default_price = parseInt(value.price) + value.discount_amount
          let new_discount_percentage = value.discount_percentage
          if(value.discount_type == 1){
              new_default_price = parseInt(value.price) + parseInt(value.discount_percentage)
              let percentage = (value.discount_percentage / new_default_price) * 100;
              new_discount_percentage = percentage
          }
          if (value.ref_table == 1) {
            // services
            this.display.payment_categories_name_array.push({ id: value.ref_id, name: value.name })
            this.form.payment_categories_id.push({
              label: value.name,
              id: value.ref_id,
              name: value.name,
              promo_id: value.promo_id,
              // price: parseInt(value.price),
              promo_amount: parseInt(value.promo_amount),
              price: parseInt(value.price),
              const_price: new_default_price,
              ref_id: value.ref_id,
              discount_percentage: new_discount_percentage,
              discount_amount: value.discount_amount,
              discount_type: value.discount_type,
              is_bundle: value.is_bundle,
              is_deposit: value.is_deposit,
              quantity: value.quantity,
              payment_detail_id: value.id,
              promo: value.promo
            })
          } else if (value.ref_table == 2) {
            // lab procedures
            this.display.lab_procedures_name_array.push({ id: value.id, name: value.name })
            this.form.lab_procedures_id.push({
              label: value.name,
              id: value.ref_id,
              name: value.name,
              promo_id: value.promo_id,
              price: parseInt(value.price),
              const_price: new_default_price,
              discount_percentage: new_discount_percentage,
              promo_amount: parseInt(value.promo_amount),
              ref_id: value.ref_id,
              discount_amount: value.discount_amount,
              discount_type: value.discount_type,
              promo: value.promo
            })
          } else if (value.ref_table == 3) {
            // medicines
            this.display.medicines_name_array.push({ id: value.id, name: value.name })
            this.form.medicines_id.push({
              label: value.name,
              id: value.ref_id,
              name: value.name,
              promo_id: value.promo_id,
              price: parseInt(value.price),
              const_price: new_default_price,
              discount_percentage: new_discount_percentage,
              promo_amount: parseInt(value.promo_amount),
              stock: value.stock,
              quantity: value.quantity,
              ref_id: value.ref_id,
              discount_amount: value.discount_amount,
              discount_type: value.discount_type,
              promo: value.promo
            })
              console.log('value ========================================', value)
              console.log('data ========================================', {
              label: value.name,
              id: value.ref_id,
              name: value.name,
              promo_id: value.promo_id,
              price: parseInt(value.price),
              const_price: new_default_price,
              discount_percentage: new_discount_percentage,
              promo_amount: parseInt(value.promo_amount),
              stock: value.stock,
              quantity: value.quantity,
              ref_id: value.ref_id,
              discount_amount: value.discount_amount,
              discount_type: value.discount_type,
              promo: value.promo
            })
          }
        })
      }
    },

    // modal patient @select
    modalPatientOnSelect(value) {
      this.form.patient_id = value.id
      this.display.patient_name = value.name
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = ''
        this.getDoctors()
      }
      this.$bvModal.hide('modal-patient')
    },

    // modal medicine @select
    modalMedicineOnSelect(value) {
      this.$refs.form.modalMedicineOnSelect(value)
    },

    // modal service @select
    modalServiceOnSelect(value) {
      this.$refs.form.modalServiceOnSelect(value)
    },

    // modal lab procedure @select
    modalLabProcedureOnSelect(value) {
      this.$refs.form.modalLabProcedureOnSelect(value)
    },

    paymentMethodOnClick(id) {
      this.form.payment_method_id = id
    },

    btnShortCutOnClick(amount) {
      if (amount > 0)
        this.form.payment_amount = parseInt(amount).toLocaleString("id-ID")
      else {
        if (this.form.payment_status_id == 2)
          this.form.payment_amount = parseInt(this.form.count_unpaid_amount).toLocaleString('id-ID')
        else
          this.form.payment_amount = parseInt(this.form.final_amount).toLocaleString('id-ID')
      }
      if (this.form.payment_status_id == 2) {
        this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.unpaid_amount
        this.form.count_unpaid_amount = (this.form.unpaid_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10))
      } else {
        this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.final_amount
        this.form.unpaid_amount = this.form.final_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10)
      }
    },

    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id
    },

    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error)
      this.form.payment_amount = 0
      this.form.change_amount = 0
    },

    modalCheckoutOnShow() {
      this.error = validation.clearValidationError(this.error)
      this.form.payment_amount = 0
      this.form.change_amount = 0
    },

    inputCashOnKeyUp(evt) {
      if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105) || evt.keyCode == 8) {
        if (this.form.payment_status_id == 2) {
          this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.unpaid_amount
          this.form.count_unpaid_amount = (this.form.unpaid_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10))
        } else {
          this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.final_amount
          this.form.unpaid_amount = this.form.final_amount - parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10)
        }
      } else {
        evt.preventDefault()
      }
    },

    btnCheckoutOnClick() {
      if (this.form.patient_id == '' || this.form.doctor_id == '') {
        Swal.fire('Peringatan', 'Harap isi kolom dokter & pasien', 'warning')
      } else if (this.form.payment_categories_id.length == 0 && this.form.medicines_id.length == 0 && this.form.lab_procedures_id.length == 0) {
        Swal.fire('Peringatan', 'Item Masih Kosong', 'warning')
      } else {
        this.$bvModal.show('modal-checkout')
      }
    },

    confirmCheckout() {
      if (this.form.payment_method_id != 1) {
        if (this.form.payment_amount != 0 && parseInt(this.form.payment_amount.replace(/\D/g, ''), 10) > this.form.final_amount) {
          Swal.fire('Gagal', 'Nominal Pembayaran tidak boleh melebihi Total Tagihan', 'warning')
        } else {
          if (this.form.payment_amount == 0) {
            Swal.fire('Gagal', 'Nominal Pembayaran belum diisi', 'warning')
          } else {
            this.actualCheckout()
          }
        }
      } else {
        if (this.form.payment_amount == 0) {
          Swal.fire('Gagal', 'Nominal Pembayaran belum diisi', 'warning')
        } else {
          this.actualCheckout()
        }
      }
    },

    async actualCheckout() {
      let form = this.form
      if (this.form.payment_method_id == 3) {
        form = new FormData()
        for (const [key, value] of Object.entries(this.form)) {
          if (key == 'payment_categories_id' || key == 'medicines_id' || key == 'lab_procedures_id') {
            form.append(key, JSON.stringify(value))
          } else {
            console.info(`append-form-${key}`, value)
            form.append(key, value)
          }
        }
      }
      let response = await module.submit(form, `payments/checkout/${this.$route.params.id}`)
      if (response.state == 'error') {
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: 'success',
        })
        this.$root.$emit('UpdateNewPayment', 'semoga jalan');
        this.getNewInvoice()
        this.$router.push(`/payment/detail/${response.success.payment.id}`)
      }
    },

    getNewInvoice() {
      console.log("jalan")
      ApiService.get("total-new-payments").then((response) => {
        console.log("new invoice", response)
        this.new_invoice = response.data
        JwtService.setNewInvoice(response.data)
      })
    },

  },

  watch: {
    'form.patient_id': function (newVal) {
      this.getDoctors()
    },
    'form.doctor_id': function (newVal) {
      if (typeof newVal === 'undefined') this.form.doctor_id = ''
    },
    'form.patient_name': function (newVal) {
      this.display.patient_name = newVal
    }
  },

  async mounted() {
    // set breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan", route: "" },
      { title: "Tambah Pembayaran" },
    ])
    // get data
    this.getDoctors()
    this.getData()
  }

}
</script>

<style></style>